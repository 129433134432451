import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'checker'
export const getSession = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
// export const checkCard = ({session,data,accountID}) =>{
export const checkCard = ({data,luu_kho,TestMode=false,CancelToken,checker,cvc}) =>{
  let req = Object.assign({},Request());
  req.cancelToken = CancelToken;
  return axiosInstance.post(`/${moduleName}`,{data,luu_kho,TestMode,checker,cvc}, req).then(ResponseData).catch(e=>e)
}
export const RandomAddress = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/address`,req).then(ResponseData).catch(ResponseError)
}
export default {
  getSession,
  checkCard,
  RandomAddress,
}