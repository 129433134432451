<template>
  <v-card>
    <v-toolbar
      :color="color||'primary'"
      flat
    >
      <template
        v-if="close"
      >
        <v-btn
          icon
          dark
          @click="doClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <slot name="button" />
    </v-toolbar>
    <v-card-title>
      <slot name="title" />
    </v-card-title>
    <slot />
  </v-card>
</template>

<script>
export default {
  props:["title","color","close"],
  methods:{
    doClose(){
      this.$emit('update:close',false)
    }
  }
}
</script>

<style>

</style>