import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'system'
export const System = (configs) =>{
  const req = Request();
  if(configs) return axiosInstance.post(`/${moduleName}`,configs,req).then(ResponseData).catch(ResponseError)
  else return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}

export default {
  System,
}