<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">CHECK HÀNG</span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col
              cols="10"
            >
              <v-textarea
                v-model="CheckData"
                label="List hàng"
                :rules="[required('List hàng')]"
                @change="doResetCache()"
              />
              <br />
              <v-row>
                <v-col
                  cols=2
                >
                  <v-checkbox
                    v-model="Filter.Die"
                    label="Hide Die"
                    color="red"
                  />
                </v-col>
                <v-col
                  cols=2
                >
                  <v-checkbox
                    v-model="luu_kho"
                    label="Tự lưu kho thẻ"
                    color="green"
                    :disabled="checking || TestMode"
                  />
                </v-col>
                <v-col
                  cols=2
                >
                  <v-select
                    class="white--text pa-2"
                    :items="AutoRetryStatus"
                    v-model="autoRetry"
                    label="Tự động Retry"
                    color="blue"
                    item-text="text"
                    item-value="val"
                    :disabled="checking"
                    @change="updateAutoRetry"
                  />
                </v-col>
                <v-col
                    cols=2
                >
                  <v-select
                      class="white--text pa-2"
                      :items="checker"
                      v-model="checkerSelected"
                      label="Checker"
                      color="blue"
                      :disabled="checking"
                      @change="updateChecker"
                  />
                </v-col>
                <v-col
                  cols=2
                >
                  <v-checkbox
                    class="white--text"
                    v-model="autoStopSockDie"
                    label="Tự động ngừng nếu sock die liên tục"
                    color="blue"
                    item-text="text"
                    item-value="val"
                    :disabled="checking"
                  />
                  <v-text-field
                    v-if="autoStopSockDie"
                    v-model="autoStopSockDieCount"
                    :prefix="`${sockDie} /`"
                    :label="`Sock Die liên tục ${autoStopSockDieCount} lần thì ngừng`"
                    :disabled="checking"
                    @change="UpdateConfigSockDie()"
                  />
                </v-col>
                <v-col
                    cols=2
                >
                  <v-checkbox
                      class="white--text"
                      v-model="cvcCheck"
                      label="Check CVC ?"
                      color="blue"
                      item-text="text"
                      item-value="val"
                      :disabled="checking"
                      @change="updateCvcCheck()"
                  />
                  <v-text-field
                      v-if="cvcCheck"
                      v-model="cvc"
                      label="CVC"
                      :disabled="checking"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="2"
            >
              <span class="indigo--text text-h5">Trạng thái: </span> <span :class="StatusClass">
                {{ CheckHangStatus[Status].t }}
                <span class="pa-2" v-if="Status === 'dang_check'">{{this.getAllChecking.length}}</span>
                <v-progress-circular
                  v-if="CheckHangStatus[Status].l"
                  indeterminate
                  :color="CheckHangStatus[Status].c"
                  :size="20"
                />
              </span>
              <br>
              <div class="pa-2">
                <v-btn
                  color="green"
                  v-if="checking === false && Status !== 'dang_huy' && getAllPending.length === 0"
                  :disabled="!valid"
                  @click="doCheck()"
                >
                  Check
                </v-btn>
                <v-row v-if="checking === false && Status !== 'dang_huy' && getAllPending.length > 0">
                  <v-col
                    cols="auto"
                  >
                    <v-btn
                      color="green"
                      @click="doResume()"
                    >
                      Resume
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                  >
                    <v-btn
                      class="pa-2"
                      color="orange"
                      @click="doResetCache()"
                    >
                      Clear
                    </v-btn>
                  </v-col>
                </v-row>
                <v-btn
                  color="orange"
                  v-if="checking === true || Status === 'dang_huy'"
                  @click="doStop()"
                  :disabled="Status === 'dang_huy'"
                >
                  <template v-if="Status === 'dang_huy'">
                    Đang hủy...
                    <v-progress-circular
                      indeterminate
                      :size="15"
                    />
                  </template>
                  <template v-else>
                    Stop
                  </template>
                </v-btn>
              </div>
              <v-progress-linear
                :value="progress"
                color="green"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{getAllChecked.length}} / {{items.length}} ({{ Math.ceil(value) }}%)</strong>
                </template>
              </v-progress-linear>
              <br/>
              <v-row>
              <v-checkbox
                class="pa-2"
                v-if="getUser.Access === 'admin'"
                v-model="TestMode"
                label="Test Mode"
                color="red"
                @change="updateTestMode"
              />
              <v-checkbox
                class="pa-2"
                v-model="FastMode"
                label="Fast Mode"
                color="green"
              />
              <v-select
                class="pa-2"
                v-model="Threads"
                :items="ThreadsMode"
                label="Threads"
                color="green"
              />
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-divider />
      </v-card-text>
      <v-card-text>
        <Datatables
          :headers="headers"
          :items="itemsWithFilter"
        >
          <template #tools>
            <v-col
              cols="1"
            >
              <v-btn
                color="green"
                outlined
                dark
                @click="loadList(checkedLive)"
                :disabled="checkedLive.length === 0"
              >
                LIVE ({{checkedLive.length}})
              </v-btn>
            </v-col>
            <v-col
              cols="1"
            >
              <v-btn
                color="red"
                outlined
                dark
                @click="loadList(checkedDie)"
                :disabled="checkedDie.length === 0"
              >
                DIE ({{checkedDie.length}})
              </v-btn>
            </v-col>
            <v-col
              cols="1"
            >
              <v-btn
                color="orange"
                outlined
                dark
                @click="loadList(checkedError)"
                :disabled="checkedError.length === 0"
              >
                ERROR ({{checkedError.length}})
              </v-btn>
            </v-col>
            <span class="pa-2 text-h6 blue--text">
              Tổng: {{itemsWithFilter.length}}
            </span>
          </template>
          <template #[`item.Status`]="{ item }">
            <template v-if="item.Status.status === 'waiting'">
              <span class="text-body2 white--text">
                ĐANG CHỜ
              </span>
            </template>
            <template v-if="item.Status.status === 'pending'">
              <v-progress-circular
                indeterminate
                color="white"
                :size="20"
              />
            </template>
            <template v-if="item.Status.status === 'cancel'">
              <span class="text-body2 red--text">
                ĐÃ HỦY
              </span>
            </template>
            <template v-if="item.Status.status === 'die'">
              <span class="text-body2 red--text">
                DIE {{(item.Status.msg) ? `-${item.Status.msg}`:''}}
              </span>
            </template>
            <template v-if="item.Status.status === 'live'">
              <span class="text-body2 green--text">
                LIVE
              </span>
            </template>
            <template v-if="item.Status.status === 'error'">
              <span class="text-body2 pink--text">
                ERROR {{(item.Status.msg) ? `-${item.Status.msg}`:''}}
              </span>
            </template>
        </template>
        </Datatables>
      </v-card-text>
    </v-card>
   <v-dialog
      v-model="Box.Show"
      max-width="900px"
    >
      <Header
        title="Danh sách card"
        :close.sync="Box.Show"
      >
        <v-card-title>Danh sách card</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="CardChecked"
            :readonly="true"
            label="Cards"
          />
        </v-card-text>
      </Header>
    </v-dialog>
    <loading :status="loading" />
    <v-overlay
      absolute
      :value="disabled"
    >
      <span class="text-h1 text-center red--text">CHECKER CLOSED</span>
    </v-overlay>
  </div>
</template>

<script>
import Header from '@/components/base/pagehead.vue'
import loading from '@/components/base/loading.vue'
import Datatables from '@/components/base/datatables.vue'
import {mapGetters} from 'vuex'
import Checker from '@/modules/checker.js'
import {ThongBao, DoiTien, CheckHangStatus} from '@/helpers'
import {setStore, getStore, removeItem} from '@/helpers/storage.js'
import {FindInArray} from '@/helpers/arrays.js'
import validations from '@/helpers/validations'
import SystemService from '@/modules/system.js';
import CryptoJS from 'crypto-js';
import axios from 'axios';
export default {
  components:{
    Header,
    loading,
    Datatables,
  },
  data () {
    return {
      disabled:false,
      FastMode:true,
      TestMode:false,
      loading: false,
      checking: false,
      checkingIndex: undefined,
      valid: false,
      luu_kho:true,
      CheckData: '',
      CardChecked: '',
      session: '',
      accountID: '',
      Status: 'san_sang',
      autoStopSockDie:false,
      autoStopSockDieCount:10,
      autoRetry:0,
      CheckHangStatus,
      AutoRetryStatus:[
        {val:0,text:'Tắt'},
        {val:1,text:'Chỉ Retry Sock Die'},
        {val:2,text:'Retry tất cả trường hợp lỗi'},
      ],
      Threads:5,
      ThreadsMode:[5,10,15,20],
      headers: [
        { text: 'STT', align: 'center', sortable: true,value: 'index',width:"5%",class:"primary secondary--text text-center" },
        { text: 'Data', align: 'center', sortable: true,value: 'data',width:"75%",class:"primary secondary--text text-center" },
        { text: 'Trạng thái', align: 'center', sortable: true, value: 'Status',width:"20%",class:"primary secondary--text text-center" },
      ],
      checked: {},
      items: [],
      Box:{
        Show: false,
      },
      Filter:{
        Die: true,
      },
      itemsCheckHash: [],
      checkingCount:0,
      CancelToken:undefined,
      sockDie:0,
      checkerError:0,
      checkerSelected:"checker4",
      configs:{},
      cvcCheck: false,
      cvc: undefined,
    }
  },
  computed:{
    ...mapGetters(['getUser']),
    itemsWithFilter(){
      const {Die} = this.Filter;
      const luu_kho = this.luu_kho;
      return this.items.map(item=>{
        if(Die === true && item.Status.status === 'die') return undefined;
        if((luu_kho === true || this.TestMode === true) && item.Status.status === 'live') return undefined;
        return item;
        // return undefined;
      }).filter(v=>!!v);
    },
    getAllChecked(){
      return this.items.filter(v=>(v.Status.status!=='waiting' && v.Status.status!=='pending'));
    },
    getAllPending(){
      return this.items.filter(v=>v.Status.status==='waiting');
    },
    getAllChecking(){
      return this.items.filter(v=>v.Status.status==='pending');
    },
    checkedLive(){
      return this.items.filter(v=>v.Status.status==='live');
    },
    checkedDie(){
      return this.items.filter(v=>v.Status.status==='die');
    },
    checkedError(){
      return this.items.filter(v=>v.Status.status==='error' || v.Status.status === 'cancel');
    },
    checker(){
      if(this.configs.CheckerGateList){
        return this.configs.CheckerGateList.split('\n');
      }
      return [];
    },
    getPending(){
      return this.getAllPending[0];
    },
    StatusClass(){
      return `text-h6 ${this.CheckHangStatus[this.Status].c}--text`;
    },
    progress(){
      const checked = this.getAllChecked.length;
      const total = this.items.length;
      return checked*100/total;
    }
  },
  watch:{
    TestMode(v){
      if(v === true){
        this.luu_kho = false;
      }
    },
    Threads(v){
      setStore('checker_Threads',v);
    },
    Status(v){
      if(v === 'hoan_tat'){
        removeItem('checker_Cache');
      }
    },
  },
  methods:{
    ...Checker,
    ...validations,
    DoiTien,
    doFetchConfig(){
      this.loader = true;
      SystemService.System().then(resp=>{
        const { data } = resp;
        if(data) this.configs = data;
      }).finally(()=>{
        this.loader = false;
      })
    },
    LoadFromCache(){
      const cache = getStore('checker_Cache');
      if(cache && typeof cache  === "object" && cache.length > 0) {
        this.items = cache.map(item=>({
          ...item,
          Status: {status:'waiting'}
        }))
      }
    },
    updateCvcCheck(){
      setStore('checker_cvc',this.cvcCheck);
      this.cvc = (this.cvcCheck) ? this.cvc:undefined;
    },
    updateChecker(){
      setStore('checker_gate',this.checkerSelected);
    },
    updateAutoRetry(){
      setStore('checker_autoRetry',this.autoRetry);
    },
    updateTestMode(){
      setStore('checker_TestMode',this.TestMode);
    },
    UpdateConfigSockDie(){
      setStore('checker_autoStopSock',this.autoStopSockDieCount);
    },
    loadList(listCard){
      let cards = '';
      listCard.map(item=>{
        cards = cards+item.data+'\n';
      })
      this.CardChecked = cards;
      this.Box.Show = true;
    },
    doResetCache(){
      this.items = [];
      removeItem('checker_Cache');
    },
    doStopAll(no_account){
      this.getAllPending.map((item)=>{
        // const hash = CryptoJS.MD5(item.data).toString(CryptoJS.enc.Hex);
        const index = FindInArray(this.items,item.data,'data');
        // this.items[index].Status = {'status':'cancel'}
        this.$set(this.items[index],'Status',{status:'cancel'});
        this.doBackup();
        // console.log(item);
      });
      if(this.getAllChecked.length !== this.items.length){
        setTimeout(()=>{
          this.doStopAll(no_account);
        },1000);
      }else{ 
        this.Status = no_account ? 'het_acc':'huy';
      }
    },
    doStop(no_account = false){
      this.checking = false;
      this.CancelToken.cancel('Cancel Check');
      this.doStopAll(no_account);
      this.Status = 'dang_huy';
    },
    doResume(){
      this.checking = true;
      this.doProcess();
    },
    doProcess(){ 
      this.Status = 'dang_check';
      this.CancelToken = axios.CancelToken.source();
      if(this.FastMode) this.doStartFast();
      else this.doStart();
    },
    doCheck(){
      this.checking = true; this.sockDie = 0;
      const items = this.CheckData.split('\n');
      let itemsCheck = []; this.itemsCheckHash = [];
      items.map(
        items => {
          if(items.trim().length > 16){
            const Data =  {
              data: items,
              Status: {status:'waiting'},
            }
            const hash = CryptoJS.MD5(items).toString(CryptoJS.enc.Hex);
            if(!this.itemsCheckHash.includes(hash)){
              itemsCheck.push(Data);
              this.itemsCheckHash.push(hash);
            }
          }
        })
      this.items = itemsCheck.map((item,index)=>({
        ...item,
        index:index+1
      })).filter(v=>!!v);
      this.doProcess();
      // this.Status = 'dang_xu_ly';

      // this.getSession().then(resp=>{
      //   const {data,error} = resp;
      //   if(!data){
      //     this.checking=false;
      //     this.Status = 'loi';
      //     return ThongBao.Error('Không thể lấy thông tin session !!!');
      //   }
      //   if(error){
      //     this.checking=false;
      //     this.Status = 'loi';
      //     return;
      //   }
      //   this.session = data.session;
      //   this.accountID = data.accountID;
      //   this.Status = 'dang_check';
      //   this.doStart();
      // }).catch(e=>{
      //   console.log('Error Session: ',e);
      //   this.checking=false;
      //   ThongBao.Error('Lỗi khi lấy thông tin Session');
      // })
    },
    doStartFast(){
      if(this.getAllChecked.length === this.items.length){
        this.Status = 'hoan_tat';
        this.checking = false;
        return;
      }
      if(this.getAllChecking.length >= this.Threads){
        setTimeout(this.doStartFast,500);
        return;
      }
      if(this.getAllChecked.length !== this.items.length){
        let map = [];
        const checker = this.getAllChecking.length;
        // const maxCheck = (this.getAllPending.length>= 20) ? 20:this.getAllPending.length;
        // console.log('Max Check: ',maxCheck);
        for(let i=checker;i<this.Threads;i++){
          map.push(this.doStart());
        }
        // this.getAllPending.forEach(item=>{
        //   map.push(this.doStart());
        // })

        if(map.length > 0){
          // console.log('Tong Thread: ',map.length);
          return Promise.all(map);
        }
      }
    },
    doStart(){
      if(this.getAllChecked.length === this.items.length){
        this.Status = 'hoan_tat';
        this.checking = false;
        return;
      }
      if(this.checking){
        const cardData = this.getPending;
        if(!cardData) return;
        const {data} = cardData;
        const realIndex = FindInArray(this.items,data,'data');
        if(this.autoStopSockDie && this.sockDie >= this.autoStopSockDieCount){
          this.doStop();
          return;
        }
        if(this.checkerError >= 5){
          this.doStop();
          return;
        }
        // const session = this.session;
        // const accountID = this.accountID;
        // this.items[realIndex].Status = {status:'pending'};
        this.$set(this.items[realIndex],'Status',{status:'pending'});
        // this.checkCard({data,session,accountID}).then(resp=>{
        const cvc = this.cvcCheck ? this.cvc:undefined;
        return this.checkCard({data:data.trim(),luu_kho:this.luu_kho,TestMode:this.TestMode,CancelToken:this.CancelToken.token,checker:this.checkerSelected,cvc:cvc}).then(resp=>{
          const {data} = resp;
          if(data){
            if(data.status === 'NO_ACCOUNT'){
              this.doStop(true);
              // this.items[realIndex].Status = {'status':'cancel'};
              this.$set(this.items[realIndex],'Status',{'status':'cancel'});
              return;
            }
            if(data.status === 'system' && data.msg === 'CHECKER CLOSED'){
              this.doStop(true);
              // this.items[realIndex].Status = {'status':'cancel'};
              this.$set(this.items[realIndex],'Status',{'status':'cancel'});
              this.disabled = true;
              return;
            }
            let doRetry = false;
            if(data.status === 'error'){
              if(data.msg === 'SOCK DIE') this.sockDie +=1;
              else this.sockDie = 0;
              if(data.msg === 'Lỗi Paygate') this.checkerError +=1;
              else this.checkerError = 0;
            }
            if(this.autoRetry > 0 && data.status === 'error'){
              if(this.autoRetry === 1 && data.msg === 'SOCK DIE'){
                doRetry = true;
              }else if(this.autoRetry === 2){
                doRetry = true;
              }
            }
            if(doRetry === true){
              this.checkingCount +=1;
              // this.items[realIndex].Status = {status:'waiting'};
              this.$set(this.items[realIndex],'Status',{'status':'waiting'});
            }else{
              // this.items[realIndex].Status = data;
              this.sockDie = 0; this.checkerError = 0;
              this.$set(this.items[realIndex],'Status',data);
            }
          }else{
            // this.items[realIndex].Status = {status:'waiting'};
            this.$set(this.items[realIndex],'Status',{'status':'waiting'});
          }
        }).catch(e=>{
          console.log('Check Error:',e);
          // this.items[realIndex].Status = {status:'waiting'};
          this.$set(this.items[realIndex],'Status',{'status':'waiting'});
        }).finally(()=>{
          // setTimeout(this.doStart,500);
          this.doBackup();
          if(!this.FastMode) setTimeout(this.doStart,500);
          else setTimeout(this.doStartFast,500);
        });
      }
    },
    doBackup(){
      const backupCache = this.items.filter(v=>(v.Status.status === 'waiting' || v.Status.status === 'pending' || v.Status.status === 'cancel' || v.Status.status === 'error'));
      setStore('checker_Cache',backupCache);
    },
    callback(){
      this.loading = false;
    },
    callbackCheck(resp){
      const {data} = resp;
      if(data){
        if(data.status === 'NO_ACCOUNT'){
          this.doStop(true);
          this.items[realIndex].Status = {'status':'cancel'};
          return;
        }
        let doRetry = false;
        if(this.autoRetry > 0 && data.status === 'error'){
          if(this.autoRetry === 1 && data.msg === 'SOCK DIE'){
            doRetry = true;
          }else if(this.autoRetry === 2){
            doRetry = true;
          }
        }
        if(doRetry === true){
          this.checkingCount +=1;
          this.items[realIndex].Status = {status:'waiting'};
        }else{
          this.items[realIndex].Status = data;
          this.checkingCount -=1;
          if(this.checkingCount < 0) this.checkingCount = 0;
        }
      }else{
        this.items[realIndex].Status = {status:'waiting'};
      }
    },
  },
  mounted(){
    this.cvcCheck = getStore('checker_cvc') || false;
    this.autoRetry = getStore('checker_autoRetry') || 0;
    this.TestMode = getStore('checker_TestMode') || false;
    this.Threads = parseInt(getStore('checker_Threads')) || 5;
    this.autoStopSockDieCount = parseInt(getStore('checker_autoStopSock')) || 10;
    this.checkerSelected = getStore('checker_gate') || "checker4";
    this.LoadFromCache();
    this.doFetchConfig();
  }
}
</script>

<style>

</style>